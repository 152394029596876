<template>
  <div class="home-content">
    <TheNav/>
    <div class="home">
      <div class="banner-area">
        <a href="https://docs.qq.com/doc/DVllBTFJzR1NWZG9V"
           target="_blank" class="big-banner">
          <img
              src="https://wechatapppro-1252524126.file.myqcloud.com/app45DDvGw38268/image/b_u_603cadb3599b5_zNW5ZUFY/e6tt5nm4nrhmrx.jpg"
              alt="首页头图"/>
        </a>
        <!--        <a href="https://premium.doyoudo.com/p/t_pc/goods_pc_detail/goods_detail/p_65f41efae4b092c1684a1df9"
                   target="_blank" class="big-banner">
                  <img
                      src="https://wechatapppro-1252524126.file.myqcloud.com/app45DDvGw38268/image/b_u_5b7457ba21ff8_i0DEvgEY/oka14ylvm7few9.jpeg"
                      alt="首页头图"/>
                </a>-->

        <!--        <a href="https://www.design006.com/?inviteCode=VTQC22LA" target="_blank" class="big-banner">
                  <img
                      src="https://wechatapppro-1252524126.file.myqcloud.com/app45DDvGw38268/image/b_u_5b7457ba21ff8_i0DEvgEY/cle9owlxl5t5ab.jpg"
                      alt="首页头图"/>
                </a>-->

        <a href="https://www.bilibili.com/cheese/play/ep6847" target="_blank" class="small-banner">
          <img
              src="https://wechatapppro-1252524126.file.myqcloud.com/app45DDvGw38268/image/b_u_5b7457ba21ff8_i0DEvgEY/z1d9cilhfnck7r.jpg"
              alt="首页小图"/>
        </a>


        <!--         <a href="https://designvue.benq.com.cn/exhibition" target="_blank" class="small-banner">
                  <img
                    src="https://wechatapppro-1252524126.cdn.xiaoeknow.com/app45DDvGw38268/image/b_u_5b7457ba21ff8_i0DEvgEY/uz7299m04sf4pe.JPG"
                    alt="首页小图" />
                </a>-->

      </div>

      <div class="control">
        <div class="searchPart">
          <div class="search">
            <input type="text" v-model="keywords" placeholder="搜索免费课程"/>

            <button @click="resetSearch" class="cancelSearch">
              <i class="ri-close-circle-line"></i>
            </button>
          </div>
          <button @click="resetList" class="all">显示全部免费课</button>
          <!-- <button class="buy">
            <a href="https://app45ddvgw38268.pc.xiaoe-tech.com">去看付费课</a>
          </button> -->
        </div>
        <div class="search-software">
          <p class="search-what">筛选软件:</p>
          <div class="buttons">
            <button v-for="(item, index) in software" :key="item.num" @click="
                item.num ? changeCategory(item.name, index) : allSoftware()
              " :class="{ active: oneCla == index }">
              <img :src="'/static/softwares/' + item.num + '.png'" v-if="item.num"/>{{ item.name }}
            </button>
          </div>
        </div>
        <div class="search-level">
          <p class="search-what">筛选难度:</p>
          <div class="buttons">
            <button v-for="(item, index) in level" :key="item.num"
                    @click="item.num ? changeLevel(item.name, index) : allLevel()" :class="{ active: twoCla == index }">
              {{ item.name }}
            </button>
          </div>
        </div>
        <div class="search-author">
          <p class="search-what">筛选作者:</p>
          <div class="buttons">
            <button v-for="(item, index) in authors" :key="item.num"
                    @click="item.num ? changeAuthor(item.name, index) : allAuthor()"
                    :class="{ active: threeCla == index }">
              {{ item.name }}
            </button>
          </div>
        </div>

        <p v-if="newDb.length > searchResult.length && searchResult.length > 0" class="found">
          找到了<span><span class="keys">{{ searchResult.length }}</span></span>个
          <span v-if="whichAuthor !== '全部作者'"><span class="keys">{{ whichAuthor }}</span>的</span><span
            v-if="keywords"
            class="keys">标题有「{{ keywords }}」的</span>
          <span v-if="whichLevel !== '全部难度'"><span class="keys">{{ whichLevel }}</span>的</span>
          <span v-if="whichSoftware !== '全部软件'"><span class="keys">{{ whichSoftware }}</span></span>课程
        </p>
        <p v-if="keywords && searchResult.length == 0" class="notFound">
          没有找到符合条件的课程
        </p>
      </div>
      <div class="cards">
        <div v-for="card in searchResult" :key="card.id" class="card">
          <router-link :to="{ name: 'ArticleDetail', params: { id: card.id } }" :article="card" target="_blank">
            <img :src="card.cover" alt="" class="cover"/>
            <div class="cardInfo">
              <h2 class="cardTitle">{{ card.ArticleTitle }}</h2>
              <div class="tags">
                <span class="software">{{ card.category }}</span>
                <span class="level">{{ card.level }}</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <p class="noMore">没有更多了</p>

      <!-- <button class="loadmore" @click="loadMore">加载更多</button> -->
    </div>
    <TheFooter/>
  </div>
</template>


<script>
import db from "../assets/db.json";
import {onMounted, ref, computed} from "vue";
import TheFooter from "../views/TheFooter.vue";
import TheNav from "../views/TheNav.vue";

export default {
  name: "Home",
  components: {TheFooter, TheNav},
  setup() {
    components: {
      TheFooter, TheNav;
    }
    const software = [
      {name: "全部软件", num: 0},
      {name: "Premiere", num: 1},
      {name: "After Effects", num: 2},
      {name: "CINEMA 4D", num: 3},
      {name: "Photoshop", num: 4},
      {name: "MasterGo", num: 15},
      {name: "Indesign", num: 5},
      {name: "illustrator", num: 6},
      {name: "Final Cut Pro", num: 7},
      {name: "Lightroom", num: 8},
      {name: "Sketch", num: 9},
      {name: "其他软件", num: 10},
      {name: "设计理论", num: 11},
      {name: "音乐音效", num: 12},
      {name: "工具推荐", num: 13},
      {name: "其他视频", num: 14},
    ];
    const authors = [
      {name: "全部作者", num: 0},
      {name: "小莫", num: 1},
      {name: "小白", num: 2},
      {name: "展哥", num: 3},
      {name: "阿思", num: 4},
      {name: "小智菌", num: 5},
      {name: "UU老师", num: 6},
      {name: "阳光", num: 7},
      {name: "MasterGo", num: 8},
    ];
    const level = [
      {name: "全部难度", num: 0},
      {name: "初级", num: 1},
      {name: "中级", num: 2},
      {name: "高级", num: 3},
    ];
    const newDb = db.filter((oneArticle) => {
      //new
      // var jsonObj = JSON.parse(oneArticle.videoInfo);
      // console.log(jsonObj);
      return oneArticle.videoInfo;
    });
    onMounted(() => {
      sofwareIcon();
    });

    const keywords = ref("");
    const oneCla = ref("0");
    const twoCla = ref("0");
    const threeCla = ref("0");
    const categoryResult = ref(newDb);
    const authorResult = ref(newDb);
    const levelResult = ref(newDb);
    const whichSoftware = ref("全部软件");
    const whichLevel = ref("全部难度");
    const whichAuthor = ref("全部作者");

    const threeResult = computed(() => {
      let cateArr = categoryResult.value;
      let levelArr = levelResult.value;
      let authArr = authorResult.value;
      let cateId = [];
      let authId = [];

      function makeId(arr, idHolder) {
        for (var i = 0; i < arr.length; i++) {
          idHolder.push(arr[i].id);
        }
      }

      makeId(cateArr, cateId);

      let catepluslevel = levelArr.filter(function (v) {
        return cateId.indexOf(v.id) !== -1;
      });

      makeId(authArr, authId);

      return catepluslevel.filter(function (v) {
        return authId.indexOf(v.id) !== -1;
      });
    });

    const searchResult = computed(() => {
      return threeResult.value.filter((oneArticle) => {
        return oneArticle.ArticleTitle.toLowerCase().includes(
            keywords.value.toLowerCase()
        );
      });
    });

    function resetList() {
      allSoftware();
      allLevel();
      allAuthor();
      resetSearch();
    }

    function resetSearch() {
      keywords.value = "";
    }

    function changeCategory(name, index) {
      oneCla.value = index;
      categoryResult.value = newDb.filter((item) => {
        return item.category == name;
      });
      whichSoftware.value = name;
    }

    function changeLevel(name, index) {
      twoCla.value = index;
      levelResult.value = newDb.filter((item) => {
        return item.level == name;
      });
      whichLevel.value = name;
    }

    function changeAuthor(name, index) {
      threeCla.value = index;
      authorResult.value = newDb.filter((item) => {
        return item.author == name;
      });
      whichAuthor.value = name;
    }

    function allSoftware() {
      categoryResult.value = newDb;
      oneCla.value = 0;
      whichSoftware.value = "全部软件";
    }

    function allLevel() {
      levelResult.value = newDb;
      twoCla.value = 0;
      whichLevel.value = "全部难度";
    }

    function allAuthor() {
      authorResult.value = newDb;
      threeCla.value = 0;
      whichAuthor.value = "全部作者";
    }

    const sofwareIcon = (name) => {
      return "../../public/static/software/" + name + ".png";
    };

    return {
      keywords,
      searchResult,
      software,
      authors,
      changeCategory,
      changeAuthor,
      resetList,
      resetSearch,
      newDb,
      sofwareIcon,
      TheFooter,
      level,
      changeLevel,
      TheNav,
      oneCla,
      twoCla,
      threeCla,
      allSoftware,
      allLevel,
      allAuthor,
      whichSoftware,
      whichLevel,
      whichAuthor,
    };
  },
};
</script>


<style scoped>
.home-content {
  background: #f5f6f9f8;
}

.home {
  width: 1240px;
  margin: 0 auto;
}

.banner-area {
  display: flex;
  justify-content: space-between;
}

.big-banner {
  width: 792px;
  height: 297px;
  margin: 8px;
  background: rgb(255, 255, 255);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.big-banner img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.small-banner {
  width: 396px;
  max-height: 297px;
  margin: 8px;
  background: rgb(255, 255, 255);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.small-banner img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.control {
  padding: 8px;
}

.searchPart {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

.control .search {
  width: 40%;
  height: 50px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  position: relative;
}

.search input {
  padding-left: 15px;
  padding-right: 100px;
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

.control input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgb(172, 172, 172);
  font-weight: lighter;
}

.control input:focus {
  border: #e4e4e4 Solid 1px;
}

.searchPart .cancelSearch {
  position: absolute;
  cursor: pointer;
  color: #545454;
  height: 50px;
  width: 60px;
  border: none;
  right: 0px;
  top: 0px;
}

.searchPart .cancelSearch:hover {
  background: #ebebeb;
}

.ri-close-circle-line {
  font-size: 1.5em;
  color: #a7a7a7;
}

.searchPart .all,
.buy,
.cop {
  border: none;
  cursor: pointer;
  border-radius: 5px;
  height: 50px;
  width: 150px;
  color: #fff;
  margin-left: 30px;
}

.searchPart .all {
  background: #a2c217;
}

.searchPart .buy {
  color: #fff;
  background: #eb5f35;
}

.searchPart .cop {
  color: #fff;
  background: #477fe9;
}

.searchPart .cop a {
  color: #fff;
}

.searchPart .buy:hover {
  background: #d85831;
}

.searchPart .buy a {
  color: #fff;
}

.searchPart .all:hover {
  background: #94b113;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.09);
}

.search-software,
.search-author,
.search-level {
  display: flex;
}

.search-software p,
.search-author p,
.search-level p {
  font-size: 14px;
  margin-right: 10px;
  padding: 6px;
  color: #80aa15;
}

.search-level {
  margin: 15px 0;
}

.control .notFound {
  color: #42130f;
  background: #fff7fa;
  border: #f9a9a2 solid 1px;
}

.control .found,
.notFound {
  font-size: 0.9em;
  text-align: center;
  margin: 20px auto;
  width: 100%;
  /* border-radius: 5px; */
  padding: 12px 0;
}

.found,
.found span {
  color: #267429;
}

.found {
  background: #f5faf5;
  border: #a9d3ab solid 1px;
  border-radius: 5px;
}

.found .keys {
  margin: 0 6px;
  font-weight: bold;
  color: #267429;
}

.control .buttons {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
}

.search button {
}

.search-software button,
.search-author button,
.search-level button {
  color: rgb(112, 112, 112);
  cursor: pointer;
  padding: 7px 12px;
  border-radius: 5px;
  border: 0;
  margin-right: 10px;
  margin-bottom: 15px;
  background: #fff;
  position: relative;
}

.control .active,
.buttons button:hover {
  color: #fff;
  background: #a2c217;
}

.buttons img {
  width: 16px;
  vertical-align: text-bottom;
  margin-right: 5px;
}

.cards {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 50px;
}

.card {
  transition: 0.3s;
  width: 294px;
  margin: 10px 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.01), 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}

.card:hover {
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.09),
  0 15px 59px 0 rgba(0, 0, 0, 0.09);
}

.card .cover {
  width: 100%;
  height: 166px;
  border-radius: 5px 5px 0 0;
}

.cardInfo {
  width: 100%;
  height: 90px;
  padding: 4px 12px 0px 12px;
  position: relative;
}

.cardInfo .cardTitle {
  font-size: 16px;
  font-weight: 400;
  color: #2a301b;
}

.card .tags {
  width: 93%;
  bottom: 10;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
}

.card .software {
  background: #efeff0;
  color: #80aa15;
}

.card .level,
.card .software {
  background: #efeff0;
  padding: 1px 6px;
  border-radius: 3px;
  font-size: 12px;
}

.noMore {
  text-align: center;
  font-size: 14px;
  width: 100%;
  margin: 20px 8px 0px;
  padding-bottom: 50px;
}

@media screen and (max-width: 470px) {
  .home {
    width: 95%;
    margin: 0 auto;
  }

  .banner-area {
    flex-wrap: wrap;
  }

  .big-banner {
    height: 160px;
  }

  .searchPart {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .search-what {
    width: 85px;
  }

  .control .search {
    width: 100%;
    margin-bottom: 10px;
  }

  .searchPart .all,
  .buy {
    margin: 10px 0;
    width: 100%;
  }

  .card {
    flex-basis: 100%;
  }

  .card .cover {
    height: 212px;
  }

  .cardInfo .cardTitle {
    font-size: 1.1em;
  }
}

@media screen and (min-width: 471px) and (max-width: 1024px) {
  .home {
    width: 95%;
    margin: 0 auto;
  }

  .banner-area {
    flex-wrap: wrap;
  }

  .big-banner {
    width: 62%;
    height: 180px;
  }

  .small-banner {
    width: 32%;
  }

  .search-what {
    width: 80px;
  }

  .cards {
    justify-content: space-between;
  }

  .card .cover {
    height: 60%;
  }

  .card {
    flex-basis: 30%;
  }

  .cardInfo .cardTitle {
    font-size: 0.9em;
  }

  .cardInfo span {
    font-size: 0.7em;
  }

  .control input {
    width: 100%;
  }

  .control .search {
    width: 60%;
  }

  .searchPart .all {
    width: 15%;
    margin: 0 15px;
  }

  .searchPart .buy {
    width: 15%;
  }
}

/* .loadmore {
  text-align: center;
  width: 1220px;
  background: #e1e1e4;
  margin: 20px 8px 100px;
  padding: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
} */

/* .loadmore:hover {
  background: #d9d9db;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.09);
} */
</style>