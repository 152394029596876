import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home'
import Landing from '../views/Landing'

const routes = [
  // {
  //   path: '/',
  //   name: 'Landing',
  //   component: Landing,
  //   props: true
  // },
  {
    path: '/',
    // path: '/',
    name: 'Home',
    component: Home,
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/resources',
    name: 'Tools',
    props: true,
    component: () => import('../views/Tools.vue')
  },
  {
    path: '/cop',
    name: 'Cop',
    props: true,
    component: () => import('../views/Cop.vue')
  },
  {
    path: '/learningpath',
    name: 'LearningPath',
    props: true,
    component: () => import('../views/LearningPath.vue')
  },
  {
    path: '/:id',
    name: 'ArticleDetail',
    component: () => import('../views/ArticleDetail.vue'),
    props: true
  },
  {
    path: '/alert',
    name: 'Alert',
    component: () => import('../views/Alert.vue'),
    props: true
  },
  {
    path: '/:catchAll(.*)',
    name: 'catchAll',
    component: Home
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
